import React from "react";
import "./Services.css";
import experience_data from "../../assets/experience_data"; // Make sure the path is correct

function Services() {
  return (
    <div id="experience" className="services">
      <div className="services-title">
        <h1>Work Experience</h1>
      </div>
      <div className="service-container">
        {experience_data.map((service, index) => (
          <div key={index} className="service-format">
            <h2>{service.job_title}</h2>
            <h3>{service.company_name}</h3>
            <h4>{service.city}</h4>
            <h3>Projects:</h3>
            <div className="service-projects">
              {service.projects.map((project, i) => (
                <div key={i} className="project-details">
                  <h2>{project.name}</h2>
                  <h3>Client: {project.company}</h3>
                  <h4>{project.date}</h4>
                  <p>{project.description}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Services;
