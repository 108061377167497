import React from "react";
import "./MyWork.css";
/* import mywork_data from "../../assets/mywork_data";
import arrow_icon from "../../assets/arrow_icon.svg"; */
import working_progress from "../../assets/working_progress.svg";

function MyWork() {
  return (
    <div id="projects" className="mywork">
      <div className="mywork-title">
        <h1>My Personal Projects</h1>
      </div>
      <div className="work-in-progress">
        <h1>Work in progress...</h1>
        <img className="progress" src={working_progress} alt="" />
      </div>
      <div className="mywork-container">
        {/* {mywork_data.map((work, index)=> {
                return <img key={index} src={work.w_img} alt="" />
            })}
        </div>
        <div className="mywork-showmore">
            <p>Show More</p>
            <img src={arrow_icon} alt="" /> */}
      </div>
    </div>
  );
}

export default MyWork;
