import React, { useState, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import AnchorLink from "react-anchor-link-smooth-scroll";
import "./NavBar.css";
import ConnectWithMe from "../ConnectWithMe/ConnectWithMe.jsx";
import menu_open from "../../assets/menu_open.svg";
import menu_close from "../../assets/menu_close.svg";

const NavBar = () => {
  const [activeMenu, setActiveMenu] = useState("home");
  const isDesktop = useMediaQuery({ minWidth: 769 });
  const menuRef = useRef();

  const openMenu = () => {
    menuRef.current.style.right = "0";
  };

  const closeMenu = () => {
    menuRef.current.style.right = "-350px";
  };

  const handleMenuClick = (menuName) => {
    setActiveMenu(menuName);
    if (!isDesktop) {
      closeMenu();
    }
  };
  const items = ["home", "about", "experience", "projects"];

  return (
    <div className="navbar">
      <h1>Federico</h1>
      <img
        src={menu_open}
        alt="Open Menu"
        onClick={openMenu}
        className="nav-mob-open"
      />
      <ul ref={menuRef} className="nav-menu">
        <img
          src={menu_close}
          alt="Close Menu"
          onClick={closeMenu}
          className="nav-mob-close"
        />
        {items.map((item) => (
          <li key={item} className={activeMenu === item ? "active" : ""}>
            <AnchorLink
              className="anchor-link"
              offset={50}
              href={`#${item}`}
              onClick={() => handleMenuClick(item)}
            >
              {item.charAt(0).toUpperCase() + item.slice(1)}
            </AnchorLink>
          </li>
        ))}
        <AnchorLink className="anchor-link" offset={50} href="#contact">
          { <ConnectWithMe onClick={() => handleMenuClick("contact")} label="Connect with Me" />}
        </AnchorLink>
      </ul>
    </div>
  );
};

export default NavBar;
