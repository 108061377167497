const experience_data = [
  {
    job_title: "Full Stack Software Developer Consultant",
    company_name: "BcSoft s.r.l.",
    city: "Bologna, Italy",
    projects: [
      {
        name: "Learning Curve Project",
        company: "BcSoft s.r.l.",
        date: "Nov 2021- Jan 2022",
        description:
          "Create Learning Management Platform to allow current & future employees access and subscription to various company-created courses",
      },
      {
        name: "Rimborsi Project",
        company: "Engineering",
        date: "Jan 2022 - Apr 2022",
        description:
          "Create a reimbursement platform for volunteers who support recovery efforts during natural disasters",
      },
      {
        name: "AQP Project",
        company: "Acquedotti Pugliesi",
        date: "Nov 2021- Jan 2022",
        description:
          "Enhance internal platform. Working in tandem as a Software Developer and Functional Analyst, " +
          "I consult with client contacts to understand their unique needs in order to properly design & " +
          "implement their specifications by taking lead on coordinating various internal Software Development & Design teams",
      },
      {
        name: "CCH Tagetik",
        company: "Wolters Kluwer",
        date: "Feb 2023 - Mar 2024",
        description:
          "Actively involved in enhancing the existing platform and implementing new features. Key " +
          "contributions include leading the development of a new reporting feature, focusing on both backend "+
          "and frontend aspects. This includes designing and developing robust backend solutions with Java, as "+
          "well as crafting intuitive and responsive user interfaces using Angular."
        },
    ],
  },
];
export default experience_data;
