import React from 'react';
import './Footer.css';
/* import ConnectWithMe from '../ConnectWithMe/ConnectWithMe.jsx'
 */

const Footer = () => {
  return (
<div className="footer">
    <div className="footer-top">
        <div className="footer-top-left">
        <h1>Federico</h1>
            <p>Full Stack Developer, specializing in both front and back-end technologies.</p>
        </div>
      {/*   <div className="footer-top-right">
             <div className="footer-email-input">
                <img src="" alt="" />
                <input type="email" />
            </div>
            <ConnectWithMe label='Subscribe'/>
        </div> */}
    </div>
    <hr/>
    <div className="footer-bottom">
        <div className="footer-bottom-left">© 2024 Federico Parrinello. All Rights Reserved </div>
        <div className="footer-bottom-right">
            <p>Terms of Services</p>
            <p>Privacy Policy</p>
        </div>
    </div>
</div>  )
}

export default Footer