import React from "react";
import "./About.css";
import profile_img from "../../assets/about_profile.png";

function About() {
  return (
    <div id='about' className="about">
      <div className="about-title">
        <h1>About Me</h1>
      </div>
      <div className="about-section">
        <div className="about-left">
          <img src={profile_img} alt="" />
        </div>
        <div className="about-right">
          <div className="about-para">
            <p>Ciao, I am Federico.</p>
            <p>
              Over the past two and a half years, I've developed my
              expertise as a software developer, sharpening my skills in Java,
              Spring, SQL, Angular, and React.
            </p>
          </div>
          <div className="about-skills">
            <div className="skill">
              <p>Java</p> <hr style={{ width: "40%" }} />
            </div>
            <div className="skill">
              <p>Spring</p> <hr style={{ width: "35%" }} />
            </div>
            <div className="skill">
              <p>HTML & CSS</p> <hr style={{ width: "20%" }} />
            </div>
            <div className="skill">
              <p>Javascript</p> <hr style={{ width: "25%" }} />
            </div>
            <div className="skill">
              <p>ReactJs</p> <hr style={{ width: "20%" }} />
            </div>
            <div className="skill">
              <p>Angular</p> <hr style={{ width: "20%" }} />
            </div>
            <div className="skill">
              <p>C#</p> <hr style={{ width: "10%" }} />
            </div>
            <div className="skill">
              <p>PowerBi</p> <hr style={{ width: "10%" }} />
            </div>
          </div>
        </div>
      </div>
      <div className="about-achievments">
        <hr />
        <div className="about-achievment">
          <h1>2+</h1>
          <p>YEARS OF EXPERIENCE</p>
        </div>
        <hr />
      </div>
    </div>
  );
}

export default About;
