import React from "react";
import "./Contact.css";
import ConnectWithMe from "../ConnectWithMe/ConnectWithMe.jsx";
/* import mail_icon from "../../assets/mail_icon.svg";
import call_icon from "../../assets/call_icon.svg"; */
import location_icon from "../../assets/location_icon.svg";
import Swal from "sweetalert2";

function Contact() {
  function showAlert(message) {
    Swal.fire({
      title: "Alert!",
      text: message,
      icon: "warning",
      confirmButtonText: "OK",
      iconColor:'#376e6f',
      background: "#d8d8d8",
      confirmButtonColor: "#da7b93"
    });
  }
  function showSuccess() {
    Swal.fire({
      title: 'Thank you!',
      text: "I'll contact you soon.",
      icon: 'success',
      confirmButtonText: 'OK',
      iconColor:'#376e6f',
      background: "#d8d8d8",
      confirmButtonColor: "#da7b93"
    });
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const name = formData.get("name");
    const email = formData.get("email");
    const message = formData.get("message");

    if (!name || !email || !message) {
      showAlert("All fields are required.");
      return;
    }
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      return showAlert("Invalid email format.");
    }

    formData.append("access_key", "641b76c1-8c8b-4608-ac30-febdbb596de5");

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: json,
      });

      const result = await response.json();

      if (result.success) {
        showSuccess();
      } else {
        showAlert("Failed to submit form.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      showAlert("An error occurred while submitting the form.");
    }
  };

  return (
    <div id="contact" className="contact">
      <div className="contact-title">
        <h1>Get in touch</h1>
      </div>
      <div className="contact-section">
        <div className="contact-left">
          <h1>Let's Connect</h1>
          <p>
            Interested in collaborating or just want to say hi? Drop me a line
            and I'll get back to you as soon as I can!
          </p>
          {/*           <div className="contact-detail">
            <img src={mail_icon} alt="" />
            <p>prova@prova.com</p>
          </div>
          <div className="contact-detail">
            <img src={call_icon} alt="" />
            <p>+1 000 0000-000</p>
          </div> */}
          <div className="contact-detail">
            <img src={location_icon} alt="" />
            <p>ON, Canada</p>
          </div>
        </div>
        <form onSubmit={onSubmit} className="contact-right">
          <label htmlFor="">Your Name</label>
          <input type="text" placeholder="Enter your Name" name="name" />
          <label htmlFor="">Your Email</label>
          <input type="text" placeholder="Enter your Email" name="email" />
          <label htmlFor="">Write your message here</label>
          <textarea
            name="message"
            rows="8"
            placeholder="Enter your Message"
          ></textarea>
          <ConnectWithMe type="submit" label="Submit" />
        </form>
      </div>
    </div>
  );
}

export default Contact;
