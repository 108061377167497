import React from "react";
import AnchorLink from 'react-anchor-link-smooth-scroll'
import "./Hero.css";
import profile_img from "../../assets/profile_img.jpeg";
import ConnectWithMe from '../ConnectWithMe/ConnectWithMe.jsx'


function Hero() {
  return (
    <div id='home' className="hero">
      <img src={profile_img} alt="" />
      <h1><span>I'm Federico Parrinello,</span> Full Stack Developer now based in Toronto, Canada.</h1>
      <p>Recently relocated from Italy, I bring a comprehensive experience as a Full Stack Developer, specializing in both front and back-end technologies.</p>
      <div className="hero-action">
      <AnchorLink className='anchor-link' offset={50} href='#contact'><ConnectWithMe label='Connect with Me'/></AnchorLink>
      </div>
    </div>
  );
}

export default Hero;
